import {
    AlertDialog,
    AlertDialogBody,
    Text,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogOverlay,
    Button,
    HStack,
    Input,
    Box,
    Select,
    Stack,
    Radio,
    RadioGroup,
    useToast
} from "@chakra-ui/react"
import dayjs from "dayjs";
import * as React from "react"
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { changeProfileInfo } from "services";
import { profileRequestIdState } from "state";
import { formatTimestampToDate, useParseBirthdate } from "utils";
interface ChangeProfileStatusProps {
    isOpen: boolean;
    onOpen: () => void; // 包含 onOpen 属性
    onClose: () => void;
    userId: number
    birthdateProp: number;
    accountStatusProp?: string;
    deviceStatusProp?: string;
}
interface ChangeProfileGenderProps {
    isOpen: boolean;
    userId: number
    onOpen: () => void;
    onClose: () => void;
    genderProp: string
}

//修改年龄、用户状态、对应设别状态
export const ChangeProfileStatus: React.FC<ChangeProfileStatusProps> = ({ isOpen, onClose, birthdateProp = 0, accountStatusProp = "", deviceStatusProp = "", userId = null }) => {
    const cancelRef = React.useRef<HTMLButtonElement>(null); // 指定类型
    const initialBirthdate = formatTimestampToDate(birthdateProp);
    const [birthdate, setBirthdate] = useState(initialBirthdate);
    const [accountStatus, setAccountStatus] = useState<string>(accountStatusProp || '');
    const [deviceStatus, setDeviceStatus] = useState<string>(deviceStatusProp || '');
    const parseBirthdate = useParseBirthdate();
    const setRequestId = useSetRecoilState(profileRequestIdState);
    const toast = useToast();
    //处理用户提交的表单信息
    const handleSubmit = async () => {
        const bir = parseBirthdate(birthdate)
        const profileProps = {
            birthday: bir,
            deviceStatus: deviceStatus,
            id: userId,
            status: accountStatus,
        }
        if (userId) {
            try {
                await changeProfileInfo(profileProps);
                setRequestId(dayjs().valueOf());
            } catch (error) {
                toast({
                    title: '更新用户资料失败',
                    status: 'error',
                    position: 'top',
                    isClosable: true,
                  });
            }
        }

        onClose();
    };
    // 处理输入框的变化
    const handleBirthdateChange = (e: { target: { value: any; }; }) => {
        const inputValue = e.target.value;
        // 只允许输入数字和 "-" 符号
        const filteredValue = inputValue.replace(/[^0-9-]/g, '');
        // 设置过滤后的值
        setBirthdate(filteredValue);
    };
    return (
        <HStack>
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent style={{ width: "300px" }}>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        <Box style={{ marginTop: "40px" }}>
                            <Text as='b' >Birthday:</Text>
                            <Input style={{ marginTop: "10px" }} placeholder='Format:YYYY-MM-DD' size='md' value={birthdate} onChange={handleBirthdateChange} />
                        </Box>
                        <Box style={{ marginTop: "30px" }}>
                            <Text as='b' >Account Status:</Text>
                            <Select
                                style={{ marginTop: "10px" }}
                                size='md'
                                name="type"
                                value={accountStatus}
                                onChange={(e) => setAccountStatus(e.target.value)}
                                isDisabled={accountStatus === '-1'}
                            >
                                {accountStatus === '-1' ? (
                                    <option value="">Deleted</option>
                                ) : (
                                    <>
                                        <option value="1">Approved</option>
                                        <option value="2">Rejected</option>
                                    </>
                                )}
                            </Select>
                        </Box>
                        <Box style={{ marginTop: "30px" }}>
                            <Text as='b' >Device Status:</Text>
                            <Select
                                style={{ marginTop: "10px" }}
                                size='md'
                                name="type"
                                value={deviceStatus}
                                onChange={(e) => setDeviceStatus(e.target.value)}
                            >
                                <option value="1">Enabled</option>
                                <option value="2">Disabled</option>
                            </Select>
                        </Box>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button colorScheme='red' ml={3} onClick={handleSubmit}>
                            Submit
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </HStack>

    )
}

//修改性别
export const ChangeProfileGender: React.FC<ChangeProfileGenderProps> = ({ isOpen, onClose, genderProp = "" ,userId = null}) => {
    const cancelRef = React.useRef<HTMLButtonElement>(null); // 指定类型
    const [gender, setGender] = useState<string>(genderProp || '');
    const setRequestId = useSetRecoilState(profileRequestIdState);
    const toast = useToast();
    //处理用户提交的表单信息
    const handleSubmit = async () => {
        const profileProps = {
            gender:Number(gender),
            id:userId
        }
        if (userId) {
            try {
                await changeProfileInfo(profileProps);
                setRequestId(dayjs().valueOf());
            } catch (error) {
                toast({
                    title: '更新用户资料失败',
                    status: 'error',
                    position: 'top',
                    isClosable: true,
                  });
            }
        }
        onClose();
    };
    return (
        <HStack>
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent style={{ width: "300px" }}>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        <Box style={{ marginTop: "30px" }}>
                            <Text as='b' >Gender:</Text>
                            <RadioGroup value={gender}
                                onChange={(value: string) => setGender(value)}
                            >
                                <Stack spacing={5} direction='row'>
                                    <Radio colorScheme='green' value='' isDisabled>
                                        None
                                    </Radio>
                                    <Radio colorScheme='green' value='1'>
                                        Man
                                    </Radio>
                                    <Radio colorScheme='green' value='0'>
                                        Woman
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </Box>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button colorScheme='red' ml={3} onClick={handleSubmit}>
                            Submit
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </HStack>
    )
}

