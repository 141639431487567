import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useToast } from '@chakra-ui/react'
dayjs.extend(customParseFormat);

// timestampMsToDateStr 将毫秒时间戳转换为日期字符串
// 格式MMM DD, YYYY HH:mm:ss
export const timestampMsToDateStr = (timestampMs?: number): string => {
  return !timestampMs
    ? "--"
    : dayjs(timestampMs).format("MMM DD, YYYY HH:mm:ss");
};

// 时间戳转年龄
export const timestampMsToAge = (timestampMs: number): number => {
  return dayjs().diff(dayjs(timestampMs), "year");
};

// 根据月份计算查询起止时间戳
// 月份格式：YYYYMM
export const getSearchTimestampsFrom = (month: string | number) => {
  const date = dayjs(String(month), "YYYYMM");

  return {
    createdStart: date.valueOf(),
    createdEnd: date.month(date.month() + 1).valueOf(),
  };
};

//时间戳转 YYYY-MM-DD 格式
export const formatTimestampToDate = (timestamp: number) => {
  // 如果时间戳为负数，返回空字符串
  if (timestamp < 0) {
    return '';
  }

  // 创建一个 Date 对象
  const date = new Date(timestamp);

  // 获取年份、月份和日期
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1
  const day = String(date.getDate()).padStart(2, '0');

  // 返回格式化的字符串
  return `${year}-${month}-${day}`;
};

//YYYY-MM-DD 转 时间戳

export const useParseBirthdate = () => {
  const toast = useToast();

  const parseBirthdate = (input: string): number | null => {
    // 正则表达式检查输入格式为 YYYY-MM-DD
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (!datePattern.test(input)) {
      toast({
        title: '输入格式不正确',
        status: 'error',
        position: 'top',
        isClosable: true,
      });
      return null; // 输入格式不正确
    }
    const date = new Date(input);
    const currentDate = new Date();
    // 检查日期是否有效
    if (isNaN(date.getTime())) {
      toast({
        title: '日期无效',
        status: 'error',
        position: 'top',
        isClosable: true,
      });
      return null; // 日期无效
    }
    // 计算年龄
    const age = currentDate.getFullYear() - date.getFullYear();
    const monthDifference = currentDate.getMonth() - date.getMonth();
    const isBirthdayPassed = monthDifference > 0 || (monthDifference === 0 && currentDate.getDate() >= date.getDate());
    const finalAge = isBirthdayPassed ? age : age - 1; // 如果生日还没到，减去1
    // 检查年龄是否在1到100岁之间
    if (finalAge < 1 || finalAge > 100) {
      toast({
        title: '超出年龄范围',
        status: 'error',
        position: 'top',
        isClosable: true,
      });
      return null; // 超出年龄范围
    }
    // 返回时间戳
    return date.getTime();
  };

  return parseBirthdate;
};
