import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { fetchReportedProfiles,fetchReportedPhotos } from "services";
import { fetchReportedStories } from "services/apis/reported-story";
import { getErrorMessage } from "utils";
import { errorState } from "./error";
import { pageSizeState } from "./pagination";
import dayjs from "dayjs";

export const reportedProfilesPageState = atom({
  key: "reportedProfiles/page",
  default: 1,
});

export const reportedProfilesRequestIdState = atom({
  key: "reportedProfiles/page/requestId",
  default: dayjs().valueOf()
})

const reportedProfilesRespQuery = selector({
  key: "reportedProfiles/resp",
  get: async ({ get }) => {
    const page = get(reportedProfilesPageState);
    const pageSize = get(pageSizeState);

    try {
      const resp = await fetchReportedProfiles({ page, pageSize });
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const reportedProfilesQuery = selector({
  key: "reportedProfiles",
  get: ({ get }) => {
    return get(reportedProfilesRespQuery)?.data ?? [];
  },
});

export const reportedProfilesCountQuery = selector({
  key: "reportedProfiles/count",
  get: ({ get }) => {
    return get(reportedProfilesRespQuery)?.page?.total ?? 0;
  },
});

//用户故事状态
export const reportedStoriesPageState = atom({
  key: "reportedStories/page",
  default: 1,
});


export const reportedStoriesRequestId = atom({
  key: "reportedStories/requestId",
  default: dayjs().valueOf(),
})

const reportedStoriesRespQuery = selector({
  key: "reportedStories/resp",
  get: async ({ get }) => {
    get(reportedStoriesRequestId)
    const page = get(reportedStoriesPageState);
    const pageSize = get(pageSizeState);

    try {      
      const resp = await fetchReportedStories({ page, pageSize });
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const reportedStoriesState = selector({
  key: "reportedStories",
  get: ({ get }) => {
    return get(reportedStoriesRespQuery)?.data ?? [];
  },
});

export const reportedStoriesCountState = selector({
  key: "reportedStories/count",
  get: ({ get }) => {
    return get(reportedStoriesRespQuery)?.page?.total ?? 0;
  },
});

//用户图片状态
export const reportedPhotosPageState = atom({
  key: "reportedPhotos/page",
  default: 1,
});
export const reportedPhotosRequestId = atom({
  key: "reportedPhotos/requestId",
  default: dayjs().valueOf(),
})
export const reportedPhotosState = selector({
  key: "reportedPhotos",
  get: ({ get }) => {
    return get(reportedPhotosRespQuery)?.data ?? [];
  },
});
const reportedPhotosRespQuery = selector({
  key: "reportedPhotos/resp",
  get: async ({ get }) => {
    get(reportedPhotosRequestId)
    const page = get(reportedPhotosPageState);
    const pageSize = get(pageSizeState);

    try {
      const resp = await fetchReportedPhotos({ page, pageSize });
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});
export const reportedPhotosCountState = selector({
  key: "reportedPhotos/count",
  get: ({ get }) => {
    return get(reportedPhotosRespQuery)?.page?.total ?? 0;
  },
});
