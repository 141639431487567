import {
  mediaStatuses,
  mediaStatusOptionState,
  monthState,
  searchUIDState,
} from "components";
import dayjs from "dayjs";
import { atom, RecoilValueReadOnly, selector, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { fetchLikesStories, fetchStories } from "services/apis/media";
import { errorState, pageSizeState } from "state";
import { MediaStatus, ReviewBody, SearchParams } from "types";
import { getErrorMessage, getSearchTimestampsFrom } from "utils";

export const storiesRequestIdState = atom({
  key: "stories/requestId",
  default: dayjs().valueOf(),
});

export const storiesPageState = atom({
  key: "stories/page",
  default: 1,
});

export const storiesRespQuery = selectorFamily({
  key: "stories/resp",
  get:
    (uid?: number) =>
    async ({ get }) => {
      get(storiesRequestIdState);

      const params: SearchParams<MediaStatus[]> = {
        page: get(storiesPageState),
        pageSize: get(pageSizeState),
      };

      if (!uid) {
        const mediaStatusValue = get(mediaStatusOptionState);

        params.status = [mediaStatuses[mediaStatusValue]];

        if (mediaStatusValue !== 0) {
          const searchTimestamps = getSearchTimestampsFrom(get(monthState));
          params.createdStart = searchTimestamps.createdStart;
          params.createdEnd = searchTimestamps.createdEnd;
        }
      } else {
        params.uid = uid;
        params.status = [
          MediaStatus.Approved,
          MediaStatus.Deleted,
          MediaStatus.Pending,
          MediaStatus.Rejected,
        ];
      }

      try {
        const resp = await fetchStories(params);
        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

// list
export const storiesQuery = selector({
  key: "stories",
  get: ({ get }) => {
    const uid = get(searchUIDState);
    return get(storiesRespQuery(+uid))?.data ?? [];
  },
});

export const storiesCountQuery = selector({
  key: "stories/count",
  get: ({ get }) => {
    const uid = get(searchUIDState);
    return get(storiesRespQuery(+uid))?.page?.total ?? 0;
  },
});

export const rejectedStoriesIdsState = atom<number[]>({
  key: "stories/rejected",
  default: [],
});

export const isStorySelectedState = selectorFamily({
  key: "isStorySelected",
  get:
    (props: { id: number; state: RecoilValueReadOnly<number[]> }) =>
    ({ get }) => {
      return get(props.state).includes(props.id);
    },
});

export const storiesToSubmitQuery = selector({
  key: "stories/submit",
  get: ({ get }) => {
    const stories = get(storiesQuery);
    const rejectedIds = get(rejectedStoriesIdsState);

    const reviewBody: ReviewBody = {
      adoptIds: [],
      refuseIds: [],
    };

    stories.forEach((story) => {
      if (rejectedIds.includes(story.id)) {
        reviewBody.refuseIds.push(story.id);
      } else if (story.status === MediaStatus.Pending) {
        reviewBody.adoptIds.push(story.id);
      }
    });

    return reviewBody;
  },
});

// profile stories
export const profileStoriesQuery = selectorFamily({
  key: "profile/stories",
  get:
    (uid: number) =>
    ({ get }) => {
      return get(storiesRespQuery(uid))?.data ?? [];
    },
});

export const profileStoriesToSubmitQuery = selectorFamily({
  key: "profile/stories/submit",
  get:
    (uid: number) =>
    ({ get }) => {
      const stories = get(profileStoriesQuery(uid));
      const rejectedIds = get(rejectedStoriesIdsState);

      const reviewBody: ReviewBody = {
        adoptIds: [],
        refuseIds: [],
      };

      stories.forEach((story) => {
        if (rejectedIds.includes(story.id)) {
          reviewBody.refuseIds.push(story.id);
        } else if (story.status === MediaStatus.Pending) {
          reviewBody.adoptIds.push(story.id);
        }
      });

      return reviewBody;
    },
});

export const profileStoriesCountQuery = selectorFamily({
  key: "profile/stories",
  get:
    (uid: number) =>
    ({ get }) => {
      return get(storiesRespQuery(uid))?.page?.total ?? 0;
    },
});

//profile liked stories
export const profileLikedStoriesQuery = selectorFamily({
  key: "profile/likedStories",
  get:
    (uid: number) =>
    ({ get }) => {
      return get(storiesLikedResQuery(uid))?.data ?? [];
    },
});
export const rejectedLikedStoriesIdsState = atom<number[]>({
  key: "likedStories/rejected",
  default: [],
});
export const likedStoriesRequestIdState = atom({
  key: "likedStories/requestId",
  default: dayjs().valueOf(),
});

export const likedStoriesPageState = atom({
  key: "likedStories/page",
  default: 1,
});

export const profileLikedStoriesCountQuery = selectorFamily({
  key: "profile/likedStories",
  get:
    (uid: number) =>
    ({ get }) => {
      return get(storiesLikedResQuery(uid))?.page?.total ?? 0;
    },
});

export const storiesLikedResQuery = selectorFamily({
  key: "likedStories/resp",
  get:
    (uid: number) =>
    async ({ get }) => {
      get(likedStoriesRequestIdState);

      const params = {
        page: get(likedStoriesPageState),
        pageSize: get(pageSizeState),
        uid: uid,
      };
      try {
        const resp = await fetchLikesStories(params);
        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const profileLikedStoriesToSubmitQuery = selectorFamily({
  key: "profile/liked/stories/submit",
  get:
    (uid: number) =>
      ({ get }) => {
        const stories = get(profileLikedStoriesQuery(uid));
        const rejectedIds = get(rejectedLikedStoriesIdsState);

        const reviewBody: ReviewBody = {
          adoptIds: [],
          refuseIds: [],
        };

        stories.forEach((story) => {
          if (rejectedIds.includes(story.id)) {
            reviewBody.refuseIds.push(story.id);
          } else if (story.status === MediaStatus.Pending) {
            reviewBody.adoptIds.push(story.id);
          }
        });

        return reviewBody;
      },
});