import { atom, selector } from 'recoil';
import { setRecoil } from 'recoil-nexus';
import { subStatus } from 'types';
import { errorState } from './error';
import { getErrorMessage, getSearchTimestampsFrom } from 'utils';
import { checkAllUserOrder, SubsParams } from 'services';
import { pageSizeState } from './pagination';
import { monthState, searchOriginalID, searchTransactionID, searchUIDState, subStatuses, subStatusState } from 'components';


export const subPageState = atom({
    key: "sub/page",
    default: 1,
  });

export const subsRespQuery = selector({
    key: "subs/resp",
    get: async ({ get }) => {
      const page = get(subPageState);
      const pageSize = get(pageSizeState);
  
      const uid = get(searchUIDState);
      const originalTransactionId = get(searchOriginalID);
      const transactionId = get(searchTransactionID)
      const source = subStatuses[get(subStatusState)];
  
      const params: SubsParams = {
        page,
        pageSize,
        productType:0
      };
  
      if (!uid && !originalTransactionId && !transactionId) {
        if (source === subStatus.AppStore) {
          params.source = 1;
        } else if(source === subStatus.GooglePlay) {
          params.source = 2;
        }
  
        const month = get(monthState);
        const searchTimestamps = getSearchTimestampsFrom(month);
  
        params.createdStart = searchTimestamps.createdStart;
        params.createdEnd = searchTimestamps.createdEnd;
      } else {
        params.id = uid ? +uid : undefined;
        params.originalTransactionId = originalTransactionId
        params.transactionId = transactionId 
      }
      try {
        const resp = await checkAllUserOrder(params);
        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
  });
export const subsQuery = selector({
    key: "subs",
    get: ({ get }) => {
      return get(subsRespQuery)?.data ?? [];
    },
  });
export const subCountQuery = selector({
    key: "sub/count",
    get: ({ get }) => {
      return get(subsRespQuery)?.page?.total ?? 0;
    },
  });

