import { privateAxios } from "services";
import { PageParams, Resp, Story } from "types";

export type ReportReason = {
  id: number;
  content: string;
  count: number;
};

export interface ReportedPhoto {
  photo: any;
  reasons: ReportReason[];
  story: Story;
  uid: number;
}

export type ReportedPhotosResp = Resp<ReportedPhoto[]>;

const API_URL_REPORTED_PHOTOS_SEARCH = "/report/photo/search";
const API_URL_REPORTED_PHOTOS_REVIEW = "/report/photo/confirm";

export interface ReportedPhotosParams extends PageParams {}

export const fetchReportedPhotos = (params: ReportedPhotosParams) => {
  return privateAxios.post<ReportedPhotosResp>(
    API_URL_REPORTED_PHOTOS_SEARCH,
    params
  );
};

export type ReviewReportedPhotosBody = {
  photoIds: number[];
};

export const reviewReportedPhotos = (body: ReviewReportedPhotosBody) => {
  return privateAxios.post(API_URL_REPORTED_PHOTOS_REVIEW, body);
};

export const reviewReportedPhoto = (id: number) => {
  return reviewReportedPhotos({ photoIds: [id] });
};
