import React from 'react';

interface ImagePreviewProps {
  image: string;
  onClose: () => void;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ image, onClose }) => {
  return (
    <div style={overlayStyle} onClick={onClose}>
      <img src={image} alt="Preview" style={imageStyle} />
    </div>
  );
};

// 样式
const overlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
};

const imageStyle: React.CSSProperties = {
  maxWidth: '90%',
  maxHeight: '90%',
  borderRadius: '8px',
};

export default ImagePreview;
