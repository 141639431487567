import {
  mediaStatusOptionState,
  mediaStatuses,
  monthState,
} from "components/filters";
import dayjs from "dayjs";
import { atom, selector, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { fetchAvatars } from "services/apis/media";
import { errorState, pageSizeState } from "state";
import { MediaStatus, ReviewBody, SearchParams } from "types";
import { getErrorMessage, getSearchTimestampsFrom } from "utils";

export const avatarsRequestIdState = atom({
  key: "avatars/requestId",
  default: dayjs().valueOf(),
});

export const avatarsPageState = atom({
  key: "avatars/page",
  default: 1,
});

export const avatarsRespQuery = selector({
  key: "avatars/resp",
  get: async ({ get }) => {
    get(avatarsRequestIdState);

    const params: SearchParams<MediaStatus[]> = {
      page: get(avatarsPageState),
      pageSize: get(pageSizeState),
    };

    const mediaStatusValue = get(mediaStatusOptionState);

    params.status = [mediaStatuses[mediaStatusValue]];

    if (mediaStatusValue !== 0) {
      const searchTimestamps = getSearchTimestampsFrom(get(monthState));
      params.createdStart = searchTimestamps.createdStart;
      params.createdEnd = searchTimestamps.createdEnd;
    }

    try {
      return await fetchAvatars(params);
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const avatarsQuery = selector({
  key: "avatars",
  get: ({ get }) => {
    return get(avatarsRespQuery)?.data.data ?? [];
  },
});

export const avatarsCountQuery = selector({
  key: "avatars/count",
  get: ({ get }) => {
    return get(avatarsRespQuery)?.data.page?.total ?? 0;
  },
});

export const rejectedAvatarsIdsState = atom<number[]>({
  key: "avatars/rejected",
  default: [],
});

export const isAvatarSelectedState = selectorFamily({
  key: "isAvatarSelected",
  get:
    (id: number) =>
    ({ get }) => {
      return get(rejectedAvatarsIdsState).includes(id);
    },
});

export const avatarsToSubmitQuery = selector({
  key: "avatars/submit",
  get: ({ get }) => {
    const avatars = get(avatarsQuery);
    const rejectedIds = get(rejectedAvatarsIdsState);

    const reviewBody: ReviewBody = {
      adoptIds: [],
      refuseIds: [],
    };

    avatars.forEach((avatar) => {
      if (rejectedIds.includes(avatar.id)) {
        reviewBody.refuseIds.push(avatar.id);
      } else if (avatar.status === MediaStatus.Pending) {
        reviewBody.adoptIds.push(avatar.id);
      }
    });

    return reviewBody;
  },
});
