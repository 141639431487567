import { HStack, Tr, Th, Td, Spinner, TableContainer, Table, Thead, Tbody, Spacer, Box, Stack, Link } from "@chakra-ui/react";
import { TransactionID, OriginalID, UIDSearchBar, MainHeader, MonthFilter, SubStatusFilter, MainFooter, Pagination } from "components"
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, Suspense } from "react";
import { useRecoilValue } from "recoil";
import { subCountQuery, subPageState, subsQuery } from "state"
import { timestampMsToDateStr } from "utils";
import { Link as RouterLink } from "react-router-dom";
import { ROUTE_URI_PROFILES } from "utils";

export function Subscriptions() {
    return (
        <Stack w="100%">
            <Stack>
                <MainHeader totalState={subCountQuery} />
                <HStack spacing='24px'>
                    <TransactionID />
                    <OriginalID />
                    <UIDSearchBar />
                    <SubStatusFilter />
                    <MonthFilter />
                </HStack >
            </Stack>
            <Box px={4}>
                <Suspense fallback={<Spinner />}>
                    <Stack>
                        <OrderTab />
                    </Stack>
                </Suspense>
            </Box>
            <Spacer />
            <MainFooter>
                <Pagination
                    state={subPageState}
                    totalState={subCountQuery}
                />
                <Spacer />
            </MainFooter>
        </Stack>
    )
}

function OrderTab() {
    const subs = useRecoilValue(subsQuery);
    return (
        <Stack>
            <TableContainer>
                <Table variant="simple" size="m">
                    <Thead>
                        <Tr>
                            <Th>Transaction ID</Th>
                            <Th>Original ID</Th>
                            <Th>UID</Th>
                            <Th>Source</Th>
                            <Th>Created At</Th>
                            <Th>Updated At</Th>
                            <Th>Product ID</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {subs.map((value: { id: Key | null | undefined; transactionId: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; originalTransactionId: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; user: { id: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }; source: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; createdAt: number; updatedAt: number; productId: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) => (
                            <Tr _hover={{ bg: "red.100" }} height="60px" key={value.id}>
                                <Td paddingRight="25px">{value.transactionId}</Td>
                                <Td paddingRight="25px">{value.originalTransactionId}</Td>
                                <Td paddingRight="25px" color="#427FF6">
                                    <Link
                                        as={RouterLink}
                                        to={`${ROUTE_URI_PROFILES}/${value.user.id}`}>
                                        {value.user.id}
                                    </Link>
                                </Td>
                                <Td paddingRight="25px">{value.source === 1 ? 'App Store' : 'Google Play'}</Td>
                                <Td paddingRight="25px">{timestampMsToDateStr(value.createdAt)}</Td>
                                <Td paddingRight="25px">{timestampMsToDateStr(value.updatedAt)}</Td>
                                <Td paddingRight="25px">{value.productId}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Stack>

    )
}
