import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { RecoilState, useRecoilState } from "recoil";

export interface SelectFilterPhotosOption {
  label: string;
  value: number;
}

export interface PhotosStatusFilterProps {
  label?: string;
  placeholder?: string;
  statusState: RecoilState<number>;
  options: SelectFilterPhotosOption[];
}

export function FilterRadioSelector(props: PhotosStatusFilterProps) {
  const [status, setStatus] = useRecoilState(props.statusState);

  const handleChange = (value: string) => {
    setStatus(+value); // 将字符串转换为数字
  };
  return (
    <Stack spacing={0} h="40px">
      <RadioGroup onChange={handleChange} value={status.toString()}>
        <Stack direction="row">
          {props.options.map((opt) => (
            <Radio key={opt.label} value={opt.value.toString()}>
              {opt.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </Stack>
  );
}
