export enum TransactionType {
  RewardForRegister = 0, // - 注册时赠送
  RandomCall = 1, // - 通话
  DirectCall = 2, // - 直拨通话
  ReopenRandomCall = 3, // -解除通话限制的通话
  Recharge = 20, //  - 充值
  ConsoleRecharge = 21, // - 后台增加/扣减
  Buy = 30, //  - 购买道具（没有存在的数据）
  SendGift = 40, //  - 礼物
  RewardForShareRegister = 50, //  - 分享注册
  RewardForShareClick = 51, //  - 分享点击
  SubscriptionGiveCoins = 52, // -订阅周期赠送
  SignInCoinRewards = 53 // 签到硬币奖励
}

export enum TransactionAction {
  Out,
  In,
}
