import { Box, Spacer, Spinner, Stack, Tab, TabList, TabPanel, Tabs, Wrap, WrapItem, TabPanels, HStack } from "@chakra-ui/react";
import {
  MainFooter,
  MainHeader,
  MediaStatusFilter,
  MonthFilter,
  Pagination,
  PhotoView,
  SubmitButton,
  mediaStatusOptionState,
} from "components";
import dayjs from "dayjs";
import { Suspense, useState } from "react";
import {
  RecoilState,
  RecoilValueReadOnly,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { reviewAvatars, reviewPhotos } from "services";
import {
  avatarsCountQuery,
  avatarsPageState,
  avatarsQuery,
  avatarsRequestIdState,
  avatarsToSubmitQuery,
  rejectedAvatarsIdsState,
  photosQuery,
  rejectedPhotosIdsState,
  photosToSubmitQuery,
  photosRequestIdState,
  photosPageState,
  photosCountQuery
} from "state";
import { Photo } from "types";

export function Photos() {
  const mediaStatusOption = useRecoilValue(mediaStatusOptionState);
  const updateAvatarRequestId = useSetRecoilState(avatarsRequestIdState);
  const updatePhotoRequestId = useSetRecoilState(photosRequestIdState);
  const [tabSta,setTabSta] = useState(0)
  const changeTab = (index:number) =>{
    setTabSta(index)
  }
  return (
    <Stack
      w="100%"
      h="100%">
      <MainHeader totalState={tabSta === 0 ? avatarsCountQuery : photosCountQuery(0)}>
        {mediaStatusOption !== 0 && <MonthFilter />}
        <MediaStatusFilter />
      </MainHeader>

      <Box px={4}>
        <Tabs isLazy onChange={changeTab}>
          <TabList>
            <Tab>Avatars</Tab>
            <Tab>Profile Photos</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Suspense fallback={<Spinner size="sm" />}>
                <PhotosWarp
                  state={avatarsQuery}
                  type="avatar"
                  rejectedPhotoIdsState={rejectedAvatarsIdsState}
                />
              </Suspense>
            </TabPanel>
            <TabPanel>
              <Suspense fallback={<Spinner size="sm" />}>
                <PhotosWarp
                  state={photosQuery(0)}
                  type="photo"
                  rejectedPhotoIdsState={rejectedPhotosIdsState}
                />
              </Suspense>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      <Spacer />

      <MainFooter>
        <Pagination
          state={tabSta === 0 ? avatarsPageState : photosPageState}
          totalState={tabSta === 0 ? avatarsCountQuery : photosCountQuery(0)}
        />
        <Spacer />
        {[0, 1].includes(mediaStatusOption) && (
          <SubmitButton
            state={tabSta === 0 ? avatarsToSubmitQuery : photosToSubmitQuery(0)}
            requestIdState={tabSta === 0 ? avatarsRequestIdState : photosRequestIdState}
            rejectedIdsState={tabSta === 0 ? rejectedAvatarsIdsState : rejectedPhotosIdsState}
            api={tabSta === 0 ? reviewAvatars : reviewPhotos}
            onFinished={() => {tabSta === 0 ? updateAvatarRequestId(dayjs().valueOf()) : updatePhotoRequestId(dayjs().valueOf())}}
          />
        )}
      </MainFooter>
    </Stack>
  );
}

export function PhotosWarp({
  state,
  type,
  rejectedPhotoIdsState,
}: {
  state: RecoilValueReadOnly<Photo[]>;
  type:string;
  rejectedPhotoIdsState: RecoilState<number[]>;
}) {
  const photos = useRecoilValue(state);
  return (
    <HStack>
      {photos.length > 0 ? (
        <Wrap>
          {photos.map((photo) => (
            <WrapItem key={photo.id}>
              <PhotoView
                w="188px"
                photo={photo}
                dataType={type}
                rejectedPhotoIdsState={rejectedPhotoIdsState}
              />
            </WrapItem>
          ))}
        </Wrap>
      ) : (<Box>-_-!</Box>)}
    </HStack>
  );
}
