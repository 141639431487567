import {  HStack, Tr, Th, Td, Spinner, TableContainer, Table, Thead, Tbody, Spacer, Box, Stack, Link } from "@chakra-ui/react";
import { OriginalID, UIDSearchBar, MainHeader, MonthFilter, SubStatusFilter, MainFooter, Pagination} from "components"
import {  Suspense} from "react";
import {  useRecoilValue} from "recoil";
import { transferCountQuery,transferPageState,transferQuery } from "state"
import { timestampMsToDateStr } from "utils";
import { Link as RouterLink } from "react-router-dom";
import { ROUTE_URI_PROFILES } from "utils";
export function Transfers() {
    return (
        <Stack w="100%">
            <Stack>
                <MainHeader totalState={transferCountQuery} />
               <HStack spacing='24px'>
                    <OriginalID />
                    <UIDSearchBar/>
                    <SubStatusFilter />
                    <MonthFilter />
                </HStack >
            </Stack>
            <Box px={4}>
                <Suspense fallback={<Spinner />}>
                <Stack>
                <TransferTab />
        </Stack>
                </Suspense>
            </Box>
            <Spacer />
            <MainFooter>
                <Pagination
                    state={transferPageState}
                    totalState={transferCountQuery}
                />
                <Spacer />
            </MainFooter>
        </Stack>
    )
}


function TransferTab() {
    const transfers = useRecoilValue(transferQuery)
    return (
        <Stack>
            <TableContainer>
                <Table variant="simple" size="m">
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Original ID</Th>
                            <Th>From UID</Th>
                            <Th>To UID</Th>
                            <Th>Source</Th>
                            <Th>Timestamp</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {transfers.map((value: any) => (
                            <Tr _hover={{ bg: "red.100" }} height="60px" key={String(value.id)}>
                                <Td paddingRight="25px">{value.id}</Td>
                                <Td paddingRight="25px">{value.originalTransactionId}</Td>
                                <Td paddingRight="25px">{value.fromUid}</Td>
                                <Td paddingRight="25px" color="#427FF6">
                                    <Link
                                        as={RouterLink}
                                        to={`${ROUTE_URI_PROFILES}/${value.toUid}`}>
                                        {value.toUid}
                                    </Link>
                                </Td>
                                <Td paddingRight="25px">{value.source === 1 ? 'App Store' : 'Google Play'}</Td>
                                <Td paddingRight="25px">{timestampMsToDateStr(value.createdAt)}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Stack>

    )

}
