// 视频
// 图片
import {
  AspectRatio,
  Box,
  HStack,
  Icon,
  IconButton,
  IconProps,
  Image,
  Progress,
  Spacer,
  Stack,
  StackProps,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ZStack } from "components/ZStack";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BsQuote } from "react-icons/bs";
import { RiCloseLine } from "react-icons/ri";
import { RxPause, RxPerson, RxPlay } from "react-icons/rx";
import { useVideo } from "react-use";
import { RecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { isPhotoSelectedState, isStorySelectedState } from "state";
import { isAvatarSelectedState } from "state/avatars";
import { Media, MediaStatus, Photo, Story } from "types/media";
import { getColorSchemeFrom } from "utils/media";
import { ProfileId } from "./ProfileId";
import ImagePreview from "./tools/PreviewMode";
export interface PhotoViewProps extends StackProps {
  photo: Photo;
  dataType: string;
  rejectedPhotoIdsState: RecoilState<number[]>;
}

// 图片
export function PhotoView({
  photo: media,
  rejectedPhotoIdsState,
  dataType,
  ...stackProps
}: PhotoViewProps) {
  const isSelected = useRecoilValue(
    dataType === "avatar"
      ? isAvatarSelectedState(media.id)
      : isPhotoSelectedState(media.id)
  );
  const setRejectedMediaIds = useSetRecoilState(rejectedPhotoIdsState);
  const [isOpen, setIsOpen] = useState(false);
  const handleSelectedMedia = () => {
    setRejectedMediaIds((prev) => {
      const rejectedIds = [...prev];
      // 检查id是否已在数组中
      const index = rejectedIds.indexOf(media.id);
      console.log("index", index, isSelected, rejectedIds);

      if (index !== -1) {
        rejectedIds.splice(index, 1);
      } else {
        rejectedIds.push(media.id);
      }

      return rejectedIds;
    });
  };
  const handleClick = () => {
    setIsOpen(true); // 打开模态框
  };

  const handleClose = () => {
    setIsOpen(false); // 关闭模态框
  };
  return (
    <VStack
      {...stackProps}
      spacing={0}
      border={"1px"}
      style={{ position: "relative" }}
      borderColor={isSelected ? "red.200" : "gray.100"}>
      <AiOutlineSearch
        style={{ position: "absolute", zIndex: "2", left: "6", top: "6" }}
        onClick={handleClick} // 点击打开模态框
      />
      <AspectRatio
        ratio={10 / 16}
        w={"100%"}
        onClick={handleSelectedMedia}
        cursor={"pointer"}>
        <Box bg={"red.100"}>
          <ZStack
            w={"100%"}
            h={"100%"}>
            <AspectRatio
              ratio={10 / 16}
              w="100%">
              <Image src={media.url} />
            </AspectRatio>
            {isSelected && (
              <AspectRatio
                ratio={10 / 16}
                w={"100%"}>
                <SelectedView />
              </AspectRatio>
            )}
          </ZStack>
        </Box>
      </AspectRatio>

      <HStack
        w={"100%"}
        p={1}
        spacing={1}
        bg={getColorSchemeFrom(media.status)}>
        <Text
          cursor={"pointer"}
          onClick={() => console.log("full screen")}
          textDecoration={
            media.status === MediaStatus.Deleted ? "line-through" : undefined
          }>
          #{media.id}
        </Text>
        <Spacer />
        <ProfileId uid={media.uid}>
          <IconButton
            variant={"ghost"}
            aria-label={"user info"}
            icon={<RxPerson />}
          />
        </ProfileId>
      </HStack>
      {/* 模态框 */}
      {isOpen && (
        <ImagePreview
          image={media.url}
          onClose={handleClose}
        />
      )}
    </VStack>
  );
}

export interface StoryViewProps extends StackProps {
  story: Story;
  rejectedStoriesIdsState: RecoilState<number[]>;
}

// 视频
export function StoryView({
  story,
  rejectedStoriesIdsState,
  ...stackProps
}: StoryViewProps) {
  const isSelected = useRecoilValue(
    isStorySelectedState({ id: story.id, state: rejectedStoriesIdsState })
  );
  const updateRejectedStoriesIds = useSetRecoilState(rejectedStoriesIdsState);

  const [video, state, controls] = useVideo(<video src={story.url} />);

  const handleSelectMedia = () => {
    updateRejectedStoriesIds((prev) => {
      const rejectedIds = [...prev];
      console.log('prev',prev);
      
      // 检查id是否已在数组中
      const index = rejectedIds.indexOf(story.id);
      console.log('previndex',index,rejectedIds);

      if (index !== -1) {
        rejectedIds.splice(index, 1);
      } else {
        rejectedIds.push(story.id);
      }

      return rejectedIds;
    });
  };

  return (
    <VStack
      {...stackProps}
      spacing={0}
      border={"1px"}
      borderColor={isSelected ? "red.200" : "gray.100"}>
      <AspectRatio
        ratio={10 / 16}
        w={"100%"}
        onClick={handleSelectMedia}
        onMouseEnter={() => controls.play()} // 鼠标移入播放 在这里调用 play
        onMouseLeave={() => controls.pause()} // 鼠标离开暂停 在这里调用 pause
        cursor={"pointer"}>
        <Box bg={"red.100"}>
          <ZStack
            w="100%"
            h="100%">
            <AspectRatio
              ratio={10 / 16}
              w="100%">
              {video}
            </AspectRatio>
            <Stack
              w="100%"
              h="100%"
              alignItems="flex-end"
              justifyContent="flex-end"
              color="white"
              p={2}>
              <Text>
                {state.playing
                  ? Math.ceil(state.duration - state.time)
                  : Math.ceil(state.duration)}{" "}
                s
              </Text>
            </Stack>

            {isSelected && (
              <AspectRatio
                ratio={10 / 16}
                w={"100%"}>
                <SelectedView />
              </AspectRatio>
            )}
          </ZStack>
        </Box>
      </AspectRatio>

      {/* Play progress */}
      <Progress
        height="1px"
        w="100%"
        colorScheme="purple"
        value={(state.time / state.duration) * 100}
      />
      <HStack
        w={"100%"}
        p={1}
        spacing={1}
        bg={getColorSchemeFrom(story.status)}>
        <IconButton
          variant={"ghost"}
          aria-label={"user info"}
          icon={state.playing ? <RxPause /> : <RxPlay />}
          onClick={state.playing ? controls.pause : controls.play}
        />
        <Text
          cursor={"pointer"}
          onClick={() => console.log("full screen")}
          textDecoration={
            story.status === MediaStatus.Deleted ? "line-through" : undefined
          }>
          #{story.id}
        </Text>
        <Spacer />

        <ProfileId uid={story.uid}>
          <IconButton
            variant={"ghost"}
            aria-label={"user info"}
            icon={<RxPerson />}
          />
        </ProfileId>
      </HStack>
      <HStack
        w={"100%"}
        p={1}
        spacing={1}
        bg={getColorSchemeFrom(story.status)}>
        {story.description && <Icon as={BsQuote} />}
        <Text
          cursor={"pointer"}
          onClick={() => console.log("select description")}>
          {" "}
          {story.description}
        </Text>
      </HStack>
    </VStack>
  );
}

export function SelectedView(props: IconProps) {
  return (
    <Icon
      as={RiCloseLine}
      color="red"
      {...props}
    />
  );
}

export function MediaStatusTag({ media }: { media: Media }) {
  return (
    <Tag colorScheme={getColorSchemeFrom(media.status)}>
      {MediaStatus[media.status].substring(0, 1)}
    </Tag>
  );
}
